import $ from './jquery.js';
import "bootstrap";
// import "popper";
import "lazysizes";
import 'jquery-match-height';
import 'owl.carousel';
import 'cookieconsent';

$(document).ready(function () {
    $('.dropdown-toggle').dropdown();

    // Cache it.
    var root = $("html, body");
    var body = $("body");
    if (window.location.hash) {
        let hash = window.location.hash.split('#')[1];
        let faqs = document.getElementsByClassName('acc__item');
        Array.from(faqs).forEach((el) => {
            if (el.getAttribute('id') == hash) {
                jQuery(el).addClass("open").removeClass("closed");
                jQuery(el).children().addClass("open").removeClass("closed").removeClass("collapsed").addClass("show");
            }
        });
    }

    if ($("#cookiestatement").length > 0) {
        var cookieUrl = $("#cookiestatement").data("cookie-url");
        var cookieMessage = $("#cookiestatement").data("cookie-message");
        var cookieDismiss = $("#cookiestatement").data("cookie-dismiss");
        var cookieLink = $("#cookiestatement").data("cookie-link");

        window.cookieconsent.initialise({
            container: document.getElementById("cookiestatement"),
            palette: {
                popup: { background: "rgb(28, 53, 70)" },
                button: { background: "#fff", text: "rgb(28, 53, 70)" },
            },
            position: "bottom-left",
            content: {
                message: cookieMessage,
                dismiss: cookieDismiss,
                link: cookieLink,
                href: cookieUrl
            }
        });
    }

    var mainMenu = $('#mainmenu');
    mainMenu.on('show.bs.collapse', '.collapse', function () {
        mainMenu.find('.collapse.show').collapse('hide');
    });

    $(".mainmenu__link").on("click", function (e) {
        if (this.pathname === window.location.pathname) {
            if ($(this).attr("href") != "#") {
                $(body).removeClass("mainmenu-open");
                $(".mainmenu").removeClass("open");
            }
        }
    });

    $('.mainmenu__nav .menu-item-has-children > .mainmenu__link').on('click', function (e) {
        $(this).toggleClass("collapsed").toggleClass("closed");
        $(this).next().collapse('toggle');
        e.preventDefault();
    });

    $(".current-menu-ancestor ul").collapse('toggle');

    var accordion = $('.accordion--main');
    accordion.on('show.bs.collapse', '.collapse', function () {
        accordion.find('.collapse.show').collapse('hide');
        $(this).parent().addClass("open").removeClass("closed");
        $(this).addClass("open").removeClass("closed");
        $(this).prev().addClass("open").removeClass("closed");
    });
    accordion.on('hide.bs.collapse', '.collapse', function () {
        $(this).parent().addClass("closed").removeClass("open");
        $(this).addClass("closed").removeClass("open");
        $(this).prev().addClass("closed").removeClass("open");
    });

    $(".menu-item-has-children > a").on('click', function (e) {
        e.preventDefault();
    });

    $(".js-btn--open-menu").on('click', function (e) {
        $(body).addClass("mainmenu-open");
        $(".mainmenu").addClass("open");
    });

    $(".js-btn--close-menu").on('click', function (e) {
        $(body).removeClass("mainmenu-open");
        $(".mainmenu").removeClass("open");
    });

    $('.special__body').matchHeight();
    $('.tweet__message').matchHeight();

    // Language toggle
    var languageBtn = $(".js-language-toggle");
    $(languageBtn).on("click", function () {
        var languageWrapper = $(this).parent();
        languageWrapper.toggleClass("language-wrapper--open");
        return false;
    });

    // Search form
    var searchButton = $(".js-search-toggle__icon");
    $(searchButton).on("click", function () {
        var searchForm = $(".search-form");
        var searchWrapper = $(this).closest(".search-wrapper");
        var searchToggle = $(this).closest(".search-toggle");
        setTimeout(function () { $(".form-control--header").focus(); }, 100);
        if (searchWrapper.hasClass("search-wrapper--open")) {
            searchForm.submit();
        } else {
            searchWrapper.addClass("search-wrapper--open");
            searchToggle.addClass("search-toggle--open").addClass("search-toggle__icon--open");

            return false;
        }

    });

    var updateContent = $(".results-wrapper").html();

    $(body).on("click", ".js-filter__button", function (e) {

        var $this = $(this);

        var filterWrapper = $(".filters");
        var filterButtons = filterWrapper.find(".js-filter__button");

        var url = filterWrapper.data("ajax-url");
        var action = filterWrapper.data("action");


        if (!$(this).is('.js-pagination__page')) {
            filterButtons.removeClass("active");
            $this.addClass("active");
            var page = filterWrapper.data("page");
        } else {
            var page = $this.data("page");
            filterWrapper.attr("data-page", 1);
        }

        // Default value
        var categoryURL = body.data('page-url');

        // Override URL with one given at filters
        if ($("section.filters .js-filter__button.active").length > 0)
            categoryURL = $("section.filters .js-filter__button.active").data("category-url");


        if (page != 1) {
            categoryURL = categoryURL + '?pag=' + page;
        }
        var category = $(".js-filter__button.active").data("category");
        var categoryID = $(".js-filter__button.active").data("category-id");
        var posts_per_page = filterWrapper.data("posts-per-page");
        var cpt = filterWrapper.data("cpt");
        var filterLang = filterWrapper.data("lang");

        $.ajax({
            url: url,
            data: {
                "action": action,
                "category": category,
                "categoryURL": categoryURL,
                "categoryID": categoryID,
                "page": page,
                "posts-per-page": posts_per_page,
                "cpt": cpt,
                "lang": filterLang
            },
            type: "POST",
            success: function (data) {
                $(".results").html(data);
                var state = { data: $(".results-wrapper").html() };
                window.history.pushState(state, "Title", categoryURL);
                $(window).on("popstate", function () {
                    if (event.state !== null) {
                        $('.results-wrapper').html(event.state.data);
                    } else {
                        // initial page
                        $('.results-wrapper').html(updateContent);
                    }
                });


                $('html, body').animate({
                    scrollTop: ($('.filters').offset().top)
                }, 500);

            }
        });

        e.preventDefault();

    });

    $(body).on("click", function (e) {
        if ($(e.target).hasClass('form-control--header') || $(e.target).hasClass('search-toggle__icon')) {
            return false;
        }

        $(".search-wrapper").removeClass("search-wrapper--open");
        $(".search-toggle").removeClass("search-toggle--open");
        $(".search-toggle__icon").removeClass("search-toggle__icon--open");
    });

    $(".js-btn-show-video").on("click", function (e) {
        var videoURL = $(this).attr("data-videoURL");
        $(".popup").addClass("popup--open");
        $('.popup iframe').attr('src', videoURL);
        e.preventDefault();
    });

    $(".js-btn-hide-video").on("click", function (e) {
        $(".popup").removeClass("popup--open");
        $('.popup iframe').attr('src', '');
        e.preventDefault();
    });

    // Newsletter
    $(body).on("click", ".js-newsletter__button", function (e) {
        $(".mc4wp-form").submit();
    });

    // Scroll to page anchor - https://stackoverflow.com/a/7717572/6396604
    $(body).on("click", "a[href^='#']", function () {
        if ($(this).attr('href').length > 1) {
            if (!$(body).hasClass("page-template-tpl-calculator")) {
                $(root).animate({
                    scrollTop: $($.attr(this, "href")).offset().top
                }, 800, "swing");

                return false;
            }
        }
    });

    // Roles
    $(body).on("mouseover", ".your-role__list-item__link", function () {
        $(this).parent().parent().addClass("hovered");
    });
    $(body).on("mouseleave", ".your-role__list-item__link", function () {
        $(this).parent().parent().removeClass("hovered");
    });

    // Carousel
    let simpleCarousels = $(".owl-carousel--simple");
    if (simpleCarousels) {
        simpleCarousels.each(function (index) {
            let el = $(this);
            let elSliderCount = el.data("count");
            el.owlCarousel({
                "items": 1,
                "loop": elSliderCount > 1 ? true : false,
                "mouseDrag": elSliderCount > 1 ? true : false,
                "pullDrag": elSliderCount > 1 ? true : false,
                "touchDrag": elSliderCount > 1 ? true : false,
                "dotsContainer": el.next().find($(".carousel__dots"))
            });

            if (elSliderCount === 1) {
                el.parent().find($('.carousel__nav')).hide();
            }

            el.next().find($(".js-carousel__btn--next")).click(function () {
                el.trigger('next.owl.carousel');
            });

            el.next().find($(".js-carousel__btn--prev")).click(function () {
                el.trigger('prev.owl.carousel');
            });
        });
    }

    let bigCarousels = $(".owl-carousel--big");
    if (bigCarousels) {
        bigCarousels.each(function (index) {
            let el = $(this);
            let elSliderCount = el.data("count");
            el.owlCarousel({
                "items": 3,
                "loop": false,
                "margin": 8,
                center: true,
                "stagePadding": 100,
                "dotsContainer": el.parent().find($(".carousel__dots")),
                onChange: function () {
                    el.parent().find($(".owl-dot span").text("").html(""));
                },
                onInitialized: counter,
                onTranslated: counter,
                responsive: {
                    // breakpoint from 0 up
                    0: {
                        "items": 1,
                        "stagePadding": 40
                    },
                    // breakpoint from 768 up
                    992: {
                        "items": 2,
                    },
                    1199: {
                        "items": 3
                    }
                }
            });

            console.log("Dots", el.parent().find($(".carousel__dots")));
        });
    }

    function counter(event) {
        console.log(event);
        console.log("HI");
        var element = event.target;         // DOM element, in this example .owl-carousel
        var items = event.item.count;     // Number of items
        var item = event.item.index + 1;     // Position of the current item
        var imageSliderTitle = $(element).find(".owl-item").eq(item - 1).find(".image-slider__title").html();

        // $(element).parent().find(".owl-dot span").text("2").html("3");
        $(element).parent().find(".carousel__nav .owl-dot span").html("");
        $(element).parent().find(".carousel__nav .owl-dot").eq(item - 1).find("span").html(imageSliderTitle);
    }

    /*

    // History carousel
    var historyCarouselEl = $('.owl-carousel--big');

    historyCarouselEl.owlCarousel({
      "items": 3,
      "loop": false,
      "margin": 8,
      center: true,
      "stagePadding": 100,
      "dotsContainer": ".carousel__dots",
      onChange: change,
      onInitialized: counter,
      onTranslated: counter,
      responsive: {
        // breakpoint from 0 up
        0: {
          "items": 1,
          "stagePadding": 40
        },
        // breakpoint from 768 up
        992: {
          "items": 2,
        },
        1199: {
          "items": 3
        }
      }
    });
    historyCarouselEl.trigger('to.owl.carousel', 1);
    var carouselElDots = carouselEl.find(".owl-dots");
    var historyCarouselElDots = historyCarouselEl.find(".owl-dots");
    var carouselElNext = $(".js-carousel__btn--next");
    carouselElDots.prepend("<span class='btn btn-text carousel__btn--next js-carousel__btn--next'><span class='ion ion-ios-arrow-forward'></span></span>");
    historyCarouselElDots.prepend("<span class='btn btn-text carousel__btn--next js-carousel__btn--next'><span class='ion ion-ios-arrow-forward'></span></span>");

    $(".js-carousel__btn--next").click(function () {
      carouselEl.trigger('next.owl.carousel');
      historyCarouselEl.trigger('next.owl.carousel');
    });

    $(".js-carousel__btn--prev").click(function () {
      carouselEl.trigger('prev.owl.carousel');
      historyCarouselEl.trigger('prev.owl.carousel');
    });

    function change() {
      $(".carousel--big .owl-dot span").text("").html("");
    }



    */

    // Spit out localStorage as an object - https://stackoverflow.com/a/17748203/6396604
    function allStorage() {
        var archive = {};
        var keys = Object.keys(localStorage);
        var i = keys.length;

        while (i--) {
            archive[keys[i]] = localStorage.getItem(keys[i]);
        }

        return archive;
    }

    // Function throttler - https://stackoverflow.com/a/4541963
    var waitForFinalEvent = (function () {
        var timers = {};

        return function (callback, ms, uniqueId) {
            if (!uniqueId) {
                uniqueId = "Don't call this twice without a uniqueId.";
            }

            if (timers[uniqueId]) {
                clearTimeout(timers[uniqueId]);
            }

            timers[uniqueId] = setTimeout(callback, ms);
        };
    })();

    // Calculator --- Initial show
    if ($("section").hasClass("calculator")) {
        // Initialise variables
        // Variables used across calctool functions start with 'calctool', those only used within conditional blocks / nested functions do not.
        var calctool = $(".calculator");
        var calctoolContainer = $(calctool).find("> div");

        var calctoolAjaxUrl = $(calctool).data("ajax-url");
        var calctoolBelowBreakpoint = window.matchMedia("(max-width: 991px)");
        var calctoolFunctionTimer = 25;

        // localStorage
        var calctoolStorageContents;
        var calctoolStorageKeysRoof;
        var calctoolStorageKeyRoofWidth;
        var calctoolStorageKeyRoofLength;
        var calctoolStorageKeyRoofLeveling;

        // Top
        var calctoolTabList = $(".calculator__menu");
        var calctoolTabListItems = $(calctoolTabList).find("> li");
        var calctoolTabListLinks = $(calctoolTabListItems).find("> *");

        // Center
        var calctoolLoadingSpinner = $(".calculator__load-spinner");
        var calctoolLoadingOverlay = $(".calculator__load-overlay");

        var calctoolPages = $(".calculator__page");
        var calctoolPage0 = $(".calculator__page").filter("#page-0");
        var calctoolPage1 = $(".calculator__page").filter("#page-1");
        var calctoolPage2 = $(".calculator__page").filter("#page-2");
        var calctoolPage3 = $(".calculator__page").filter("#page-3");
        var calctoolPage4 = $(".calculator__page").filter("#page-4");
        var calctoolPage5 = $(".calculator__page").filter("#page-5");
        var calctoolSummary = $(".calculator__page").filter("#summary");
        var calctoolPageFields = $(calctoolPages).find("input[type='email'], input[type='number'], input[type='text']");

        // Bottom
        var calctoolBottom = $(".calculator__bottom");

        var calctoolWarningSelection = $(calctoolBottom).find(".warning--selection");

        var calctoolButtons = $(".calculator__button");
        var calctoolButtonsLink = $(calctoolButtons).find("> a");
        var calctoolHomeButton = $(calctoolButtons).filter(".calculator__button--home");
        var calctoolRestartButton = $(calctoolButtons).filter(".calculator__button--restart");
        var calctoolNavButtons = $(calctoolBottom).find(calctoolButtons);
        var calctoolNavButtonPrev = $(calctoolNavButtons).filter(".calculator__button--prev");
        var calctoolNavButtonNext = $(calctoolNavButtons).filter(".calculator__button--next");

        // Actual magic starts here
        var pageHighest = JSON.parse(localStorage.getItem("calctool_page_highest"));

        if (pageHighest === null || pageHighest !== 0) {
            calctoolClearAllStorage("restart");
        }

        // Calculator --- Initialise tooltips
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        // Calculator --- Only allow 1 open tooltip
        $("[data-toggle='tooltip']").on("click", function () {
            let openTooltip = $(this).parents(".calculator__page").find("[data-tooltip-shown='true']");
            if (openTooltip.length > 0) {
                openTooltip.tooltip("hide");
            }

            $(this).attr("data-tooltip-shown", true);
        });

        calctoolGetNextStep("#page-0");
    }

    // Calculator --- Tabs - menu click
    $(calctoolTabListLinks).click(function (e) {
        e.preventDefault();

        var currentTab = $(calctoolTabListLinks).filter(".active")[0];
        var targetTab = $(this)[0];

        if (currentTab === targetTab) {
            return false;
        } else {
            calctoolTabToggler(targetTab, currentTab);
        }
    });

    // Calculator --- Tabs - button click
    $(calctoolButtonsLink).click(function (e) {
        // console.log("calctoolNavButtonsLink click.");

        e.preventDefault(); // Prevents the addition of the .calculator__page id to the URL.

        var currentTab = $(calctoolTabListLinks).filter(".active")[0];
        var currentTabIndex = JSON.parse(localStorage.getItem("calctool_currentTabIndex"));
        var targetTabIndex;
        var targetTab;
        var button = $(this).parent();

        if (button.is(calctoolNavButtonPrev)) {
            if (currentTabIndex > 0) {
                targetTabIndex = currentTabIndex - 1;
                targetTab = calctoolTabListLinks.get(targetTabIndex);

                calctoolWarningToggler("hide");
            }
        } else if (button.is(calctoolNavButtonNext)) {
            // Current tab is BETWEEN the first & second to last #page.
            if (currentTabIndex > -1 && currentTabIndex < calctoolTabListLinks.length - 1) { // Use length - 1 because length isn't an index and thus starts at 1 instead of 0.
                targetTabIndex = currentTabIndex + 1;
                targetTab = calctoolTabListLinks.get(targetTabIndex);
            }
            // Current tab is the last #page.
            else if (currentTabIndex === calctoolTabListLinks.length - 1) {
                targetTab = "#summary";
            }
            // Current tab is #summary.
            else if (currentTabIndex === 999999) {
                calctoolSendMail();
                calctoolClearAllStorage();

                return false;
            }
        } else if (button.is(calctoolHomeButton) || button.is(calctoolRestartButton)) {
            calctoolTabHide();

            if (button.is(calctoolHomeButton)) {
                calctoolFinished("home");
            } else if (button.is(calctoolRestartButton)) {
                calctoolFinished("restart");
            }
        }

        calctoolTabToggler(targetTab, currentTab);
    });

    // Calculator --- Tabs - toggler
    function calctoolTabToggler(targetTab, currentTab) {
        // console.log("In calctoolTabToggler.");

        var currentTabIndex = $.inArray(currentTab, calctoolTabListLinks);
        var targetTabIndex;

        var activePage = $(calctoolPages).filter(".active");
        var activePageReqFields = $(activePage).find(".field.is-required");
        var activePageReqFieldAmount = activePageReqFields.length;

        if (typeof (targetTab) !== "string") {
            targetTabIndex = $.inArray(targetTab, calctoolTabListLinks);
        }
        // Target tab is #summary.
        else {
            targetTabIndex = 999999;
        }

        // The following is (currently) always true:
        // 1. When selected, .tile gets .selected assigned. There can only be one .tile.selected per page.
        // 2. All elements with .field.required assigned must (of course) all be filled in.
        // POSSIBLE FUTURE IMPROVEMENT: don't compare .field.is-required's length to .is-valid's length, instead check if the actual elements match.
        if (
            $(activePage).find(".selected").length ||
            activePageReqFieldAmount > 0 && activePageReqFieldAmount === $(activePage).find(".is-valid").length
        ) {
            // targetTab is #summary OR targetTab is positioned after currentTab
            if (targetTabIndex === 999999 || targetTabIndex > currentTabIndex) {
                var pageHighest = localStorage.getItem("calctool_page_highest");

                if (targetTabIndex >= pageHighest) {
                    localStorage.setItem("calctool_page_highest", targetTabIndex);
                }

                // targetTab is #summary OR targetTab comes directly after currentTab
                if (targetTabIndex === 999999 || targetTabIndex === currentTabIndex + 1) {
                    switcher(currentTab, targetTab);
                } else {
                    calctoolStorageContents = allStorage();
                    var storageRegEx = new RegExp("calctool_page-" + targetTabIndex + "_selection" + "(-[0-9])*");

                    $.each(calctoolStorageContents, function (key, value) {
                        // Hypothetical case:
                        // Last stored selection is page-3, user goes back to page-1, doesn't change the selection and goes back to page-3 > allowed.
                        // Last stored selection is page-3, user goes back to page-1, doesn't change the selection and wants to go directly to page-4 > not allowed. User must first visit page-3 after which he/she can continue to page-4.
                        // Changing this behavior is not only difficult but may also be unwanted from a UX perspective.
                        if (storageRegEx.test(key)) {
                            switcher(currentTab, targetTab);

                            return false;
                        }
                    });
                }
            } else {
                switcher(currentTab, targetTab);
            }
        } else {
            // targetTab is NOT #summary AND targetTab is positioned before currentTab
            if (targetTabIndex !== 999999 && targetTabIndex < currentTabIndex) {
                calctoolWarningToggler("hide");

                switcher(currentTab, targetTab);
            } else {
                calctoolCheckPageInput($(currentTab).attr("href"));

                calctoolWarningToggler("show");

                if (activePageReqFieldAmount > 0 && activePageReqFields !== $(activePageReqFields).hasClass("is-valid")) {
                    activePageReqFields.find("input").addClass("has-warning");
                }

                return false;
            }
        }

        function switcher(currentTab, targetTab) {
            // console.log("In switcher.");

            if (targetTab === "#summary") {
                localStorage.setItem("calctool_currentTabIndex", 999999);

                calctoolGetNextStep("#summary");
            } else {
                var targetTabIndex = $.inArray(targetTab, calctoolTabListLinks);
                var pageHighest = localStorage.getItem("calctool_page_highest");
                var pageSelectionChanged = localStorage.getItem("calctool_page-selection-changed");
                if (targetTabIndex >= pageHighest || pageSelectionChanged) {
                    var targetPage = $(targetTab).attr("href");

                    if (targetPage.match(/(#page-[1-3]{1})/)) {
                        calctoolGetNextStep(targetPage);
                    } else {
                        calctoolTabHide(currentTab);

                        if (targetPage.indexOf("#page-4") !== -1) {
                            var inputRoofLeveling = $(calctoolPage4).find("#roofsize-leveling");

                            if ($(calctoolPage3).find(".tile.selected").data("prod-code") !== "level") {
                                $(inputRoofLeveling).parents(".field.is-required").removeClass("is-required");

                                if ($(inputRoofLeveling).val() !== "") {
                                    $(inputRoofLeveling).val("");

                                    $.each(calctoolStorageContents, function (key) {
                                        var storageKey = /selection-roofsize-leveling/.test(key);

                                        if (storageKey) {
                                            localStorage.removeItem(key);
                                        }
                                    });
                                }

                                $(inputRoofLeveling).parents(".margin-row--fields").css("display", "none");
                            } else {
                                if ($(inputRoofLeveling).parents(".margin-row--fields").css("display", "none")) {
                                    $(inputRoofLeveling).parents(".field").addClass("is-required");
                                    $(inputRoofLeveling).parents(".margin-row--fields").css("display", "");
                                }
                            }
                        }

                        calctoolTabShow(targetTab);
                    }
                } else {
                    calctoolTabHide(currentTab);
                    calctoolTabShow(targetTab);
                }
            }
        }
    }

    // Calculator --- Tabs - hide
    function calctoolTabHide(tab) {
        // console.log("In calctoolTabHide.");

        var anchorEl;

        if (tab === undefined || tab === "#summary") {
            anchorEl = calctoolTabList;

            if (tab === "#summary") {
                $(calctoolBottom).css("display", "none");
                $(calctoolSummary).find(".calculator__title").css("display", "none");
                $(calctoolSummary).find(".calculator__content").css("display", "none");
            }
        } else {
            let currentPage = $(tab).attr("href");
            let openTooltip = $(currentPage).find("[data-tooltip-shown='true']");
            if (openTooltip.length > 0) {
                openTooltip.tooltip("hide");
            }

            $(tab).tab("dispose");
            $(tab).removeClass("active");

            $(calctoolPages).filter(".active").removeClass("active show");

            if (calctoolBelowBreakpoint.matches) {
                anchorEl = calctoolContainer;
            } else {
                anchorEl = tab;
            }
        }

        $(anchorEl)[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center"
        });
    }

    // Calculator --- Tabs - show
    function calctoolTabShow(tab) {
        // console.log("In calctoolTabShow.");

        var targetTabIndex = $.inArray(tab, calctoolTabListLinks);

        localStorage.setItem("calctool_currentTabIndex", JSON.stringify(targetTabIndex));

        $(tab).tab("show");

        calctoolButtonsCheck(tab);
    }

    // Calculator --- Buttons - change values
    function calctoolButtonsCheck(targetTab) {
        // console.log("In calctoolButtonsCheck.");

        var tabListLinksCurrentState = calctoolTabListLinks.prop("nodeName").toLowerCase();
        var buttonPrevCurrentState = $(calctoolNavButtonPrev).css("display");
        var buttonNextCurrentString = document.querySelector(".calculator__button--next > a").childNodes[0].nodeValue;

        var tabListLinksTargetState;
        var buttonPrevTargetState;
        var buttonNextTargetString;

        if (targetTab === "#summary") {
            tabListLinksTargetState = "span";
            buttonPrevTargetState = "none";

            if (buttonNextCurrentString !== calctoolNavButtonNext.data("string-mail")) {
                buttonNextTargetString = $(calctoolNavButtonNext).data("string-mail");
            }
        } else if ($(targetTab).attr("href").indexOf("#page") !== -1) {
            tabListLinksTargetState = "a";

            if ($(targetTab).attr("href").indexOf("#page-0") !== -1) {
                buttonPrevTargetState = "none";
            } else {
                buttonPrevTargetState = "block";
            }

            if ($(targetTab).attr("href").indexOf("#page-5") !== -1) {
                if (buttonNextCurrentString !== $(calctoolNavButtonNext).data("string-summary")) {
                    buttonNextTargetString = $(calctoolNavButtonNext).data("string-summary");
                }
            } else {
                buttonNextTargetString = $(calctoolNavButtonNext).data("string-next");

                if (buttonNextCurrentString !== $(calctoolNavButtonNext).data("string-next")) {
                    buttonNextTargetString = $(calctoolNavButtonNext).data("string-next");
                }
            }
        }

        // calctoolTabListLinks - change element type.
        if (tabListLinksCurrentState.indexOf(tabListLinksTargetState) === -1) {
            $(calctoolTabListLinks).each(function () {
                var newEl = $("<" + tabListLinksTargetState + "><" + tabListLinksTargetState + "/>");

                $.each(this.attributes, function () {
                    newEl.attr(this.name, this.value);
                });

                $(this).replaceWith(newEl);
                $(newEl).html($(this).html());
            });
        }

        // Button previous - change "display" state.
        if (buttonPrevCurrentState.indexOf(buttonPrevTargetState) === -1) {
            $(calctoolNavButtonPrev).css("display", buttonPrevTargetState);
        }

        // Button next - change text.
        if (buttonNextCurrentString.indexOf(buttonNextTargetString) === -1) {
            $(calctoolNavButtonNext).find("> a").contents().each(function () {
                if (this.nodeType === 3) {
                    document.querySelector(".calculator__button--next > a").childNodes[0].nodeValue = buttonNextTargetString;
                }
            });
        }
    }

    function calctoolSelectionCss(targetOption) {
        calctoolWarningToggler("hide");

        var activePageSelectedOption = $(calctoolPages).filter(".active").find(".selected");

        if ($(activePageSelectedOption)) {
            if ($(activePageSelectedOption).has(".tile__img").length) {
                $(activePageSelectedOption).find("> *").removeClass("has-border");
            } else if ($(activePageSelectedOption).has(".radio").length) {
                $(activePageSelectedOption).removeClass("has-border");
                $(activePageSelectedOption).find("input").prop("checked", false);
            }

            $(activePageSelectedOption).removeClass("selected");
        }

        if ($(targetOption).is("a.tile")) {
            $(targetOption).find(".tile__img").parent().addClass("has-border");
            $(targetOption).addClass("selected");

            activePageSelectedOption = $(targetOption);
        } else if ($(targetOption).is("input[type='radio']")) {
            $(targetOption).parents(".tile").addClass("selected has-border");

            activePageSelectedOption = $(targetOption).parents(".tile");
        }

        calctoolStoreSelection(activePageSelectedOption[0]);
    }

    function calctoolOnInput(inputField) {
        var fieldWrapper = $(inputField).parents(".field");
        var value = inputField[0].value;

        let numericMin = 0;
        let numericMax = 999;
        let valid;

        if ($(inputField).is("[type='email']")) {
            valid = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i; // https://stackoverflow.com/a/13178771/6396604
        } else if ($(inputField).hasClass("input__name")) {
            valid = /^[A-Za-z '.-]*$/; // This field won't accept names written in non-Latin characters.
            //} else if ($(inputField).is("[type='number']")) {
        } else if ($(inputField).hasClass("input__number")) {

            if (typeof value !== "string") {
                value = value.toString();
            }

            // Replace comma
            //value = value.replace(/([,]*)+/g, ".");
            value = value.trim().replace(',', '.');

            // Convert numeric string to actual number
            value = Number(value);

            // Minimum
            if (value < numericMin) {
                // value = numericMin;
                inputField[0].value = numericMin;
            }

            // Maximum
            if ($(inputField).is("#roofsize-leveling")) {
                numericMax = 33;
            }

            if (value > numericMax) {
                // value = numericMax;
                inputField[0].value = numericMax;
            }

            //inputField[0].value = value;

            valid = /^[0-9,.]+$/; // This field won't accept non-Arabic numberals.
        }

        if (value) {
            if (!$(fieldWrapper).hasClass("has-value")) {
                $(fieldWrapper).addClass("has-value");
            }
        } else {
            if ($(fieldWrapper).hasClass("has-value")) {
                $(fieldWrapper).removeClass("has-value");
            }
        }

        if (value !== "" && valid.test(value)) {
            if ($(fieldWrapper).hasClass("is-invalid")) {
                $(fieldWrapper).removeClass("is-invalid");
            }
            if (!$(fieldWrapper).hasClass("is-valid")) {
                $(fieldWrapper).addClass("is-valid");
            }

            if ($(inputField).hasClass("has-warning")) {
                $(inputField).removeClass("has-warning");
            }
        } else {
            if ($(fieldWrapper).hasClass("is-valid")) {
                $(fieldWrapper).removeClass("is-valid");
            }
            if (!$(fieldWrapper).hasClass("is-invalid")) {
                $(fieldWrapper).addClass("is-invalid");
            }
        }
    }

    function calctoolCheckPageInput(page) {
        // console.log("In calctoolCheckPageInput.");

        var pageReqFields = $(page).find(".field.is-required");
        var pageReqValidFieldsAmount = $(page).find(".field.is-required.is-valid").length;
        var pageReqFieldAmount = pageReqFields.length;

        if (pageReqFieldAmount !== pageReqValidFieldsAmount) {
            $.each(pageReqFields, function () {
                if (!$(this).hasClass("has-value")) {
                    if (!$(this).hasClass("is-valid") && !$(this).hasClass("is-invalid")) {
                        $(this).addClass("is-invalid");
                    }
                }
            });
        }
    }

    $(calctoolPageFields).on("input", function () {
        // console.log("Field input detected.");

        var theField = $(this);

        waitForFinalEvent(function () {
            calctoolOnInput($(theField));
            calctoolStoreSelection($(theField).parents(".field"));
            calctoolWarningToggler("typing");

        }, calctoolFunctionTimer, "calctoolPageFields");
    });

    function calctoolWarningToggler(state) {
        // console.log("In calctoolWarningToggler.");

        var activePage = $(calctoolPages).filter(".active");
        var activePageValidFieldAmount = $(activePage).find(".field.is-valid").length;
        var activePageReqFields = $(activePage).find(".field.is-required");
        var activePageReqFieldAmount = activePageReqFields.length;

        if (state === "hide" || state === "typing" && activePageReqFieldAmount === activePageValidFieldAmount) {
            if ($(calctoolWarningSelection).hasClass("is-visible")) {
                $(calctoolBottom).removeClass("has-warning");

                $(calctoolWarningSelection).slideUp(200, function () {
                    $(this).removeClass("is-visible");
                    $(this).attr("aria-hidden", true);
                });
            }

            if (activePageReqFields.find("input").hasClass("has-warning")) {
                activePageReqFields.find("input.has-warning").removeClass("has-warning");
            }
        } else if (state === "show") {
            if (!$(calctoolWarningSelection).hasClass("is-visible")) {
                $(calctoolBottom).addClass("has-warning");

                $(calctoolWarningSelection).slideDown(200, function () {
                    $(this).addClass("is-visible");
                    $(this).attr("aria-hidden", false);
                });
            }
        }
    }

    function calctoolStoreSelection(option) {
        var activePage = $(calctoolPages).filter(".active");
        var activePageReqFields = $(activePage).find(".field.is-required");

        var optionData;
        var storageKey;
        var storageVal;
        var optionId;

        if ($(option).is("a.tile")) {
            optionData = $(option).data("prod-code");

            storageVal = "tile-" + optionData;
        } else if ($(option).has(".radio").length) {
            optionData = $(option).find("input").data("prod-code");

            storageVal = "radio-" + optionData;
        } else if ($(option).hasClass("field")) {
            optionData = $(option).find("input").val();

            storageVal = "field-" + optionData;
        }

        var currentTabIndex = JSON.parse(localStorage.getItem("calctool_currentTabIndex"));

        if (activePageReqFields.length === 0) {
            storageKey = "calctool_page-" + currentTabIndex + "_selection";
        } else {
            optionId = $(option).find("input").attr("id");

            storageKey = "calctool_page-" + currentTabIndex + "_selection-" + optionId;
        }

        var pageStoredVal = localStorage.getItem(storageKey);

        if (pageStoredVal === null && optionData !== "") {
            localStorage.setItem(storageKey, storageVal);
        } else if (pageStoredVal !== storageVal) {
            if (!$(option).hasClass("field")) {
                localStorage.setItem("calctool_page-selection-changed", true);
                localStorage.setItem("calctool_page_highest", currentTabIndex);
            }

            if (optionData === "") {
                if (localStorage.getItem(storageKey) !== null) {
                    localStorage.removeItem(storageKey);
                }
            } else {
                localStorage.setItem(storageKey, storageVal);

                var currentTab = $(calctoolTabListLinks).filter(".active")[0];
                var currentPageIndexStr = $(currentTab).attr("href").replace("#page-", "");
                var currentPageIndexInt = parseInt(currentPageIndexStr);
                var nextPageIndex = currentPageIndexInt + 1;

                calctoolStorageContents = allStorage();

                $.each(calctoolStorageContents, function (key) {
                    var pageStr = key.replace(/calctool_|_selection(-[^-]*[^-])*/g, "");
                    var pageIndexInt = parseInt(pageStr.replace("page-", ""));

                    if (pageIndexInt >= nextPageIndex) {
                        if (key.indexOf("name") === -1 && key.indexOf("email") === -1) {
                            localStorage.removeItem(key);

                            var thisPage = $(calctoolPages).filter("#" + pageStr);

                            if ($(thisPage).has(".field").length) {
                                var thisInput = key.replace(/calctool_page-[0-9]+_selection[-]{1}/, "#");

                                $(thisInput).val("");
                                $(thisInput).parents(".field").removeClass("has-value");

                                if ($(thisInput).parents(".field").hasClass("is-required")) {
                                    $(thisInput).parents(".field").removeClass("is-valid");
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    function calctoolClearAllStorage(action) {
        // console.log("In calctoolClearAllStorage.");

        calctoolStorageContents = allStorage();

        $.each(calctoolStorageContents, function (key) {
            var storageKey = /calctool_/.test(key);

            if (storageKey) {
                localStorage.removeItem(key);
            }
        });

        if (action === "restart") {
            localStorage.setItem("calctool_currentTabIndex", "0");
            localStorage.setItem("calctool_page_highest", "0");
        }
    }

    function calctoolFinished(action) {
        $(calctoolPages).filter(".active").removeClass("active show");

        $(calctoolLoadingSpinner).addClass("show");
        $(calctoolLoadingOverlay).addClass("show");

        if (action === "home") {
            window.location.href = siteBaseUrl;
        } else if (action === "restart") {
            window.location.reload(true);
        }
    }

    function calctoolGetNextStep(targetPage) {
        // console.log("In calctoolGetNextStep.");

        var targetStep;

        if (targetPage === "#page-0") {
            targetStep = "product";
        } else if (targetPage === "#page-1") {
            targetStep = "design";
        } else if (targetPage === "#page-2") {
            targetStep = "tile";
        } else if (targetPage === "#page-3") {
            targetStep = "rooftype";
        } else if (targetPage === "#summary") {
            targetStep = "summary";
        }

        var stepAction;
        var targetEl;
        var selectedProduct;
        var selectedDesign;
        var selectedSize;
        var selectedRooftype;

        if (targetStep === "product") {
            stepAction = "calculator_step_products";
            targetEl = $(calctoolPage0).find(".calculator__data");
        } else if (targetStep === "design") {
            stepAction = "calculator_step_designs";
            targetEl = $(calctoolPage1).find(".calculator__data");

            selectedProduct = $(calctoolPage0).find(".tile.selected").data("prod-code");
        } else if (targetStep === "tile") {
            stepAction = "calculator_step_tiles";
            targetEl = $(calctoolPage2).find(".calculator__data");

            selectedProduct = $(calctoolPage0).find(".tile.selected").data("prod-code");
            selectedDesign = $(calctoolPage1).find(".tile.selected").data("prod-code");
        } else if (targetStep === "rooftype") {
            stepAction = "calculator_step_rooftypes";
            targetEl = $(calctoolPage3).find(".calculator__data");

            selectedProduct = $(calctoolPage0).find(".tile.selected").data("prod-code");
            selectedDesign = $(calctoolPage1).find(".tile.selected").data("prod-code");
            selectedSize = $(calctoolPage2).find(".tile.selected input").data("prod-code");
        } else if (targetStep === "summary") {
            stepAction = "calculator_step_calculation";

            selectedProduct = $(calctoolPage0).find(".tile.selected").data("prod-code");
            selectedDesign = $(calctoolPage1).find(".tile.selected").data("prod-code");
            selectedSize = $(calctoolPage2).find(".tile.selected input").data("prod-code");
            selectedRooftype = $(calctoolPage3).find(".tile.selected").data("prod-code");
        }

        return $.ajax({
            url: calctoolAjaxUrl,
            type: "POST",
            beforeSend: function () {
                var currentTab = $(calctoolTabListLinks).filter(".active")[0];

                calctoolTabHide(currentTab);

                $(calctoolLoadingSpinner).addClass("show");
                $(calctoolLoadingOverlay).addClass("show");
            },
            data: {
                "action": stepAction,
                "product": selectedProduct,
                "design": selectedDesign,
                "tile": selectedSize,
                "rooftype": selectedRooftype
            }
        }).done(function (data) {
            if (targetStep === "summary") {
                calctoolGetFinalCalculation(data);
            } else {
                $(targetEl).html(data);

                localStorage.removeItem("calctool_page-selection-changed");

                var calctoolPageOptions = $(targetEl).find("[data-prod-code]");

                $(calctoolPageOptions).click(function (e) {
                    if ($(this).is("a.tile")) {
                        e.preventDefault();
                    }

                    calctoolSelectionCss($(this)[0]);
                });

                $(calctoolLoadingSpinner).removeClass("show");
                $(calctoolLoadingOverlay).removeClass("show");

                var targetTab = $(calctoolTabListLinks).filter("[href='" + targetPage + "']")[0];

                calctoolTabShow(targetTab);
            }
        }).fail(function () {
            console.log("An error occurred while making the AJAX call.");
        });
    }

    function calctoolGetFinalCalculation(calculationId) {
        // console.log("In calctoolGetFinalCalculation.");

        calctoolStorageContents = allStorage();
        var storageKeysPattern = /calctool_page-[0-9]+_selection-roofsize/;

        $.each(calctoolStorageContents, function (key, value) {
            if (storageKeysPattern.test(key)) {
                calctoolStorageKeysRoof = value.replace("field-", "");

                if (key.indexOf("width") !== -1) {
                    calctoolStorageKeyRoofWidth = calctoolStorageKeysRoof;
                } else if (key.indexOf("length") !== -1) {
                    calctoolStorageKeyRoofLength = calctoolStorageKeysRoof;
                } else if (key.indexOf("leveling") !== -1) {
                    calctoolStorageKeyRoofLeveling = calctoolStorageKeysRoof;
                }
            }
        });

        let stepAction = $(calctool).data("action");
        let name = $(calctoolPage5).find(".input__name").val();
        let email = $(calctoolPage5).find(".input__email").val();

        $.ajax({
            url: calctoolAjaxUrl,
            type: "POST",
            data: {
                "action": stepAction,
                "calculation_id": calculationId,
                "name": name,
                "email": email,
                "width": calctoolStorageKeyRoofWidth,
                "length": calctoolStorageKeyRoofLength,
                "slope": calctoolStorageKeyRoofLeveling
            }
        }).done(function (data) {
            $(calctoolSummary).find(".calculator__content").html(data);

            $(calctoolLoadingSpinner).removeClass("show");
            $(calctoolLoadingOverlay).removeClass("show");
            $(calctoolSummary).addClass("active show");

            calctoolButtonsCheck("#summary");
        }).fail(function () {
            console.log("An error occurred while making the AJAX call.");
        });
    }

    function calctoolSendMail() {
        var alertMail = $(calctoolSummary).find(".alert--mail");
        var name = $(calctoolPage5).find(".input__name").val();
        var email = $(calctoolPage5).find(".input__email").val();
        var entry = $(calctoolSummary).find("input[name=entry]").val();
        var message;

        $.ajax({
            url: calctoolAjaxUrl,
            type: "POST",
            beforeSend: function () {
                calctoolTabHide("#summary");

                $(calctoolLoadingSpinner).addClass("show");
                $(calctoolLoadingOverlay).addClass("show");
            },
            data: {
                "action": 'calculator_send_mail',
                "name": name,
                "email": email,
                "entry": entry
            }
        }).done(function (data) {
            if (data.status == 'ok') {
                message = $(alertMail).data("string-success");
            } else {
                console.log("An error occurred while sending the mail.");

                message = $(alertMail).data("string-error");
            }

            $(alertMail).find("> p").html(message);
            $(alertMail).removeClass("hide");

            $(calctoolLoadingSpinner).removeClass("show");
            $(calctoolLoadingOverlay).removeClass("show");
        }).fail(function () {
            console.log("An error occurred while making the AJAX call.");

            message = $(alertMail).find("> p").data("string-error");

            $(alertMail).find("> p").html(message);
            $(alertMail).removeClass("hide");

            $(calctoolLoadingSpinner).removeClass("show");
            $(calctoolLoadingOverlay).removeClass("show");
        });
        ;
    }


    // Video overlay function
    // Video
    if ($('.media__ratio--video').length) {
        let videoIds = [];
        let ytVideoTag = document.createElement('script');
        ytVideoTag.src = "https://s.ytimg.com/yts/jsbin/www-widgetapi-vflioRwVn/www-widgetapi.js";
        ytVideoTag.setAttribute('onload', 'onYouTubeIframeReady()');
        let ytVideoTagFirstScriptTag = document.getElementsByTagName('script')[0];
        ytVideoTagFirstScriptTag.parentNode.insertBefore(ytVideoTag, ytVideoTagFirstScriptTag);
        $('.media__ratio--video').each(function () {
            let videoIFrame = $(this).find('iframe.media__source--video');
            let videoId = videoIFrame.attr('id');
            videoIds.push(videoId);
            $(this).on("click", function () {
                $(this).find(".media__overlay--img").fadeOut();
                $(this).find(".media__overlay").fadeOut();
                $(this).find(".media__source--video").addClass("is-active");
                // $(this).find(".media__source--video").attr('src') + '?autoplay=1';
                videoIFrame.src += "&autoplay=1&mute=1";
                playVid(videoId);
                return false;
            });
        });
        // //
        window.onYouTubeIframeReady = function () {
            videoIds.forEach(function (videoId) {
                window['yt-video-' + videoId + '-player'] = new YT.Player(videoId, {
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange
                    },
                    playerVars: { rel: 0, showinfo: 0, ecver: 2 }
                });
            })
        }
        function onPlayerReady(event) {
            // event.target.playVideo();
        }
        function playVid(videoId) {
            if (window['yt-video-' + videoId + '-player']) {
                window['yt-video-' + videoId + '-player'].playVideo();
            }
        }
    }
});


